import { render, staticRenderFns } from "./BiographyBuilderLayoutSection.vue?vue&type=template&id=83d7d76a&scoped=true&"
import script from "./BiographyBuilderLayoutSection.vue?vue&type=script&lang=js&"
export * from "./BiographyBuilderLayoutSection.vue?vue&type=script&lang=js&"
import style0 from "./BiographyBuilderLayoutSection.vue?vue&type=style&index=0&id=83d7d76a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d7d76a",
  null
  
)

component.options.__file = "BiographyBuilderLayoutSection.vue"
export default component.exports
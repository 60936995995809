<template>
    <div class="main-card" :class="[{'component-active': isActive}]" 
         @click="updateCurrentComponent">
         <div class="row">
            <input type="text" v-model="name" :placeholder="$t('characterNamePlaceholder')">
            <input type="text" v-model="period" :placeholder="$t('CharacterPeriodOfTime')">
         </div>
        <ckeditor :editor="editor" :placeholder="$t('biographyMainTextPlaceHolder')"
                v-model="content" :config="editorConfig"></ckeditor>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'MainCard', 
    mixins:[BackOfficeComponentMixin],     
    computed:{
        name:{
            get(){
                return this.component.name[this.locale]
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'name', this.locale)
            }
        },
        period:{
            get(){
                return this.component.period
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'period')
            }
        },
        content:{
            get(){
                return this.component.content[this.locale] || "";
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'content',this.locale)
            }
        }, 
        essentialQuestion:{
            get(){
                return this.component.essentialQuestion     
                ? this.component.essentialQuestion.question[this.locale]
                : ''
            }, 
            set(value)
            {
                let essentialQuestion = {
                    ...this.component.essentialQuestion
                }
                essentialQuestion.question[this.locale] = value
                this.setComponentPropertyValue(essentialQuestion, 'essentialQuestion')
            }
        },
    }
}
</script>
<style lang="less">
    .main-card {
        cursor: pointer;
        max-height: 350px;
        height: auto;
        width: 464px;
        margin-left: 16px;
        padding-bottom: 16px;
        padding: 16px;
        box-sizing: border-box;
        flex-direction: column;
        >*{
            margin-top: 4px;
        }
        .ck-editor{
            margin-top: 4px;
        }
        .ck-editor__main, .ck-content{
            max-height: 220px;
            max-height: 220px;
        }

        >.row{
            input:first-of-type{
                margin-right: 8px;
                min-width: 67%;
            }
        }

    }
</style>

<template>
    <div class="row" v-cloak>
        <div class="column">
            <div class="biography-layout row">
                <div class="portrait column">
                    <div class="field-placeholder row">
                        <span>{{$t('portraitTag')}}</span>
                    </div>
                    <div class="field-placeholder"></div>
                </div>
                <div class="main-card column">
                    <div class="field-placeholder"></div>
                    <span>{{$t('mainCardTag')}}</span>
                </div>
                <div class="complementary-content row">
                    <span>{{this.$t('complementaryText')}}</span>
                </div>
                <div class="main-ilustration">
                    <div class="field-placeholder column">
                        <span>{{this.$t('mainIlustrationTag')}}</span>
                    </div>
                </div>
            </div>
            <div class="components-container column">
                <draggable class="draggable-section"  v-if="components" v-model="components" :options="dragOptions"
                    :move="onMove" @change="onChange">
                    <transition-group name="flip-list" tag="ul">
                        <li  v-for="(component, index) in components" :key="index" >
                            <biography-component :component="component"/>  
                        </li>
                    </transition-group>
                </draggable>
            </div>
        </div>
        <components-catalog/>
    </div>
</template>
<script>
import BiographyComponentLayout from '@/components/BackOffice/BiographyBuilder/BiographyComponentLayout.vue'
import ComponentsCatalog from '@/components/BackOffice/LayoutComponents/ComponentsCatalog.vue'
import LayoutSectionMixin from '@/components/BackOffice/Mixins/LayoutSectionMixin.js'
import Factory from '@/utils/ContentFactory.js'

export default {
    name: 'BiographyLayoutSection',
    mixins:[LayoutSectionMixin],
    components:{
        'biography-component': BiographyComponentLayout, 
        'components-catalog':ComponentsCatalog, 
    }, 
    methods:{        
        onChange({added, moved}){
            if(added){
                let paragraph = new Factory.CreateParagraph(added.newIndex +1, added.element)
                paragraph.biographyId = this.$route.params.biographyId,
                this.$store.dispatch('createBiographyBuilderComponent', paragraph)
            }
            if(moved){
                let component = this.components.find(e => e.id === moved.element.id)
                this.$store.dispatch('reoderBiographyComponent', component)
            }
        },
    }, 
    computed:{
        components:{
            get()
            {
                if(!this.$store.state.BiographyBuilderComponent)
                    return
                return this.$store.state
                    .BiographyBuilderComponent.paragraphs || []
            },
            set(value){
                let components = value.filter(e => e.id)
                this.$store.commit('updateBiographyComponentsOrder', components)
            }
        }, 
    }

}
</script>

<style lang="less" scoped>
.biography-layout{
    position: relative;
    width: 704px;
    margin-left: 28px;
    padding-left: 10px;
    .main-card {
        height: 264px;
        width: 464px;
        margin-left: 16px;
        flex-direction: column;
        span{
            margin: auto;
            transform: translate(0, -8px)
        }
        .field-placeholder{
            height: 40px;
            width: 440px;
            margin: 8px auto;
        }
    }
    .portrait {
        height: 264px;
        width: 224px;
        flex-direction: column;

        .field-placeholder:first-child{
            height: 184px;
            width: 192px;
            margin: 16px;
            span{
                margin: auto;
            }
        }

        .field-placeholder:nth-child(2){
            height: 40px;
            width: 192px;
            margin: -8px 16px;
        }

    }
    .complementary-content{
        height: 264px;
        width: 224px;
        margin-top: 16px;
        span{
            white-space: normal;
            height: auto;;
            min-width: 193px;
            font-size: 19px;
            text-align: center;
            margin: auto;
        }
 
    }
    .main-ilustration{
        height: 264px;
        width: 464px;
        margin-left: 16px;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .field-placeholder{
            height: 100%;
            width: 100%;
            span{
                margin: auto;
                white-space: normal;
                height: auto;
            }
        }
    }
    & /deep/ .drag-drop-elements{
        height: calc(100vh - 176px);
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }
    }
}

.components-catalog{
    height: calc(100vh - 120px);
}


</style>



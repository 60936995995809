<template>
    <div class="row" v-if="biography"> 
        <div class="components-container column custom-scrollbar">
            <div class="biography-content flex-container">
                <portrait-component :component="portrait"/>
                <main-card :component="mainCard"/>
                <complementary-content :component="complementaryContent" />
                <main-ilustration :component="mainIlustration"/>
            </div>
                <component  v-for="(component, index) in components"
                :is="component.type" :key="index" :component="component"/>        
        </div>
        <properties-panel :save-component="saveBiography"/>
    </div>
</template>
<script>
import PropertiesPanel from '@/components/BackOffice/PropertiesPanel.vue'
import SectionTitleComponent from '@/components/BackOffice/ContentComponents/SectionTitleComponent.vue'
import CardComponent from '@/components/BackOffice/ContentComponents/CardComponent.vue'
import ParagraphComponent from '@/components/BackOffice/ContentComponents/ParagraphComponent.vue'
import TextContentComponent from '@/components/BackOffice/ContentComponents/TextContentComponent.vue'
import VideoContentComponent from '@/components/BackOffice/ContentComponents/VideoContentComponent.vue'
import ComplementaryContent from '@/components/BackOffice/BiographyBuilder/BiographyComponents/ComplementaryContent.vue'
import MainCard from '@/components/BackOffice/BiographyBuilder/BiographyComponents/MainCard.vue'
import MainIlustration from '@/components/BackOffice/BiographyBuilder/BiographyComponents/MainIlustration.vue'
import PortraitComponent from '@/components/BackOffice/BiographyBuilder/BiographyComponents/PortraitComponent.vue'
import InteractiveMapComponent from '@/components/BackOffice/ContentComponents/InteractiveMapComponent.vue'
import OnlyImagesComponent from '@/components/BackOffice/ContentComponents/OnlyImagesComponent.vue'

export default {
    name: 'BiographyContentSection', 
    components:{
        'properties-panel':PropertiesPanel, 
        'section-title': SectionTitleComponent, 
        'card': CardComponent,
        'paragraph': ParagraphComponent,
        'text-content': TextContentComponent,
        'video-content': VideoContentComponent, 
        'interactive-map': InteractiveMapComponent,
        'only-images': OnlyImagesComponent,
        ComplementaryContent,
        MainCard,
        MainIlustration,
        PortraitComponent
    }, 
    data(){
        return{
            biographyUpdatedMessage: this.$t('biographyUpdatedMessage'),
            unableToSaveChangesAlert: this.$t('unableToSaveChangesAlert'), 
            biographyUpdateErrorMessage: this.$t('biographyUpdateErrorMessage')
        }
    },
    computed:{
        biography(){
            return this.$store.state.BiographyBuilderComponent
        },
        components(){
            if(!this.biography)
                    return
                return this.biography.paragraphs || []
        }, 
        component(){
            return this.$store.state.currentBackOfficeComponent
        }, 
        portrait(){
            return this.biography.portrait
        }, 
        mainCard(){
            return this.biography.mainCard
        }, 
        mainIlustration(){
            return this.biography.media
        }, 
        complementaryContent(){
            return this.biography.complementaryContent
        }
    }, 
    methods:{
        keydown(e){
            if(e.keyCode == 27)
                this.updateCurrentComponent()
        },
        updateCurrentComponent(){
            this.$store.commit('updateCurrentBackOfficeComponent',this.biography)
        },
        addAlerts(component){
            component.errors.forEach(error => {
                let errorNotification = this.$store.state.notifications
                    .find(e => e.message === error)
                if(!errorNotification){
                    this.$store.commit('addSnackBarNotification', {
                        message: error, 
                        dismissable: true
                    })
                }
            });
        },
        validateComponentFields(component){
            if(component.errors.length > 0){
                this.addAlerts(component)
                throw this.unableToSaveChangesAlert
            }
        }, 
        saveBiography(){   
            try {
                this.components.map(e =>{this.saveBiographyBuilderComponent(e)})
                this.saveBiographyMainContent(this.biography)
                this.$store.commit('addSnackBarNotification',{
                    message: this.biographyUpdatedMessage, 
                    dismissable: false
                })
            } catch (error) {
                if(error!= ''){
                this.$store.commit('addSnackBarNotification',{message: error})
                }
            }  
        },
        setComponentPropertyValue(component, value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: component, propertyName: propertyName, value: value})
        },
        saveBiographyBuilderComponent(component){
            this.validateComponentFields(component)

            if(component.audio)    
                this.setComponentPropertyValue(component, component.audio.id, 'audioId')
            component.images.map((e)=>{
                this.$store.dispatch('updateImageDetails', e)
                    .catch(()=>{
                        throw  this.biographyUpdateErrorMessage
                    })})
            component.routePoints.forEach((e)=>{
                if(e.audio)
                    this.setComponentPropertyValue(e, e.audio.id, 'audioId')
                this.$store.dispatch('updateRoutePoint', e)
            })
            this.$store.dispatch('updateBiographyBuilderComponent', component)
                .catch(()=>{throw  this.biographyUpdateErrorMessage})
        },

        saveBiographyMainContent(component){
            this.validateComponentFields(this.mainIlustration)
            if(component.portrait.id)
            {
                this.$store.dispatch('updateImageInfo',{
                    imageId: component.portrait.id,
                    info:{
                        caption: component.portrait.caption,
                        disclaimer: component.portrait.disclaimer
                    }
                })
            }

            let essentialQuestionAction = !component.mainCard.essentialQuestion.id
                ? 'createEssentialQuestion' : 'updateEssentialQuestion'

            if(essentialQuestionAction){
                let mainIlustration = this.mainIlustration;
                this.$store.dispatch(essentialQuestionAction, component.mainCard.essentialQuestion)
                    .then((response)=>{
                        let essentialQuestion ={...component.mainCard.essentialQuestion}
                        if(!essentialQuestion.id)
                            essentialQuestion.id = response.data

                        this.setComponentPropertyValue(component.mainCard, essentialQuestion.id, 'essentialQuestionId')
                        let audioId=  component.mainCard.audio ?
                            component.mainCard.audio.id : 0
                        this.setComponentPropertyValue(component.mainCard, audioId, 'audioId')

                        let biographyMainContent ={
                            ...component,
                            id: component.id,
                            portraitId: component.portrait.id || 0,
                            complementaryText: this.complementaryContent.text,
                            audioPortraitId: component.portrait.audio.id,
                            audioComplementaryText: this.complementaryContent.audio.id
                        }
                        biographyMainContent.mainCard = component.mainCard
                        let media ={
                            imageId: mainIlustration.image.id || 0,
                            audioImageId: mainIlustration.audioImage?mainIlustration.audioImage.id || 0:0,
                            captionImage: mainIlustration.captionImage,
                            videoUrl: mainIlustration.videoUrl
                        }
                        biographyMainContent.media = media

                        this.$store.dispatch('updateBiographyContent', biographyMainContent)
                    })
                    .catch(()=>{
                        throw this.biographyUpdateErrorMessage
                    })
            }

        }
    },
    created(){
        this.$store.commit('updateCurrentBackOfficeComponent', undefined)
    }, 
    mounted(){
        document.addEventListener("keydown",this.keydown);
    },
    destroyed(){
        document.removeEventListener("keydown", this.keydown);
    }
}
</script>
<style lang="less" scoped>

.biography-content{
    min-width: 704px;
    >div {
        height: 350px;
        background-color: #EFEFEF;
        box-shadow: var(--primary-shadow);
    }
    >.component-active{
        background-color: #fff;
        box-shadow: var(--secondary-shadow);
    }
    .portrait, .complementary-content{
        width: 224px;
    }
}

</style>

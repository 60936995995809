import { render, staticRenderFns } from "./PortraitComponent.vue?vue&type=template&id=78594185&scoped=true&"
import script from "./PortraitComponent.vue?vue&type=script&lang=js&"
export * from "./PortraitComponent.vue?vue&type=script&lang=js&"
import style0 from "./PortraitComponent.vue?vue&type=style&index=0&id=78594185&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78594185",
  null
  
)

component.options.__file = "PortraitComponent.vue"
export default component.exports